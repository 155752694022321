import { sendPost } from '../index'

// 登录
export interface LogInRes {
    access_token: string,
    token_type: string,
    expires_in: number,
}

export const logInModule = {
    // 登录
    login: (data: any) => sendPost<LogInRes>('/backend/auth/login', data),
}