import { sendGet, sendPost } from '../index'
import type { ListRes } from '../type'

export interface GetTicketListRes {
    id: number
    mini_user_id: number
    departure: string
    destination: string
    departure_date: string
    return_date: string
    type: number
    adult_count: number
    baby_count: number
    created_at: string
    updated_at: string
    way: number
    ticket_plan_id: number
    order: {
        status_text: string
        status: number
    }
}

export interface TripType {
    arrival_at: string
    arrival_position: string
    departure_at: string
    departure_position: string
}
export interface GetPlanListRes {
    order_id: number
    id: number
    user_id: number
    created_at: string
    total_price: string
    departure_flight: TripType[]
    return_flight: TripType[]
}

export const ticketListModule = {
    //获取机票列表
    getTicketList: (data) => sendGet<ListRes<GetTicketListRes>>('/backend/order-air-ticket/get-list', data),
    //回复客户
    ticketContactCustomer: (data) => sendPost('/backend/order/reply-air-ticket', data),
    //获取方案列表
    getPlanList: (data) => sendGet<GetPlanListRes[]>('/backend/order-air-ticket/get-ticket-plans', data),
    //方案审核
    planConfirm: (data) => sendPost('/backend/order/review', data)
}