// 路由加载
import './RouteLoading.scss'
const RouteLoading = () => {
    
    return (
        <div className="RouteLoading">
            <div className="loader"></div>
        </div>
    )
}

export default RouteLoading