import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// BrowserRouter 路由历史模式    HashRouter 路由哈希模式
import { HashRouter } from 'react-router-dom';

import { Provider } from "react-redux"
import store from "./redux/store"

import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import 'moment/locale/zh-cn'
import zhCN from 'antd/es/locale/zh_CN';

import api from './api/index'
React.$api = api

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhCN}>
    <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
    </HashRouter>
  </ConfigProvider>
  //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();