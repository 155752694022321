import { sendGet } from '../index'
import type { ListRes } from '../type'

export interface GetUserListRes {
    id: number,
    created_at: string,
    updated_at: string
}
export const userListModule = {
    //获取小程序用户列表
    getUserList: (data) => sendGet<ListRes<GetUserListRes>>('/backend/mini-user/get-list', data)
}