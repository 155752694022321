import { combineReducers } from 'redux'
import { LOGINEXPIRESCHANGE, WHOLELOADINGCHANGE } from './const'

// 登录是否过期
const initLoginExpires = false
const loginExpires = (prevState = initLoginExpires, action: any) => {
    const { type, data } = action
    switch (type) {
        case LOGINEXPIRESCHANGE:
            return data
        default:
            return prevState
    }
}

// 全局加载效果
const initWholeLoading = false
const WholeLoading = (prevState = initWholeLoading, action: any) => {
    const { type, data } = action
    if (data) {
        document.body.classList.add("globalClassOne") 
    } else {
        document.body.classList.remove("globalClassOne") 
    }
    switch (type) {
        case WHOLELOADINGCHANGE:
            return data
        default:
            return prevState
    }
}

// 汇总reducers
export default combineReducers({
    loginExpires,
    WholeLoading
}) 