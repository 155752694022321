import { sendGet } from '../index'
import type { ListRes } from '../type'

export interface GetHotelListRes {
    id: number
    mini_user_id: number
    departure: string
    destination: string
    departure_date: string
    return_date: string
    type: number
    adult_count: number
    baby_count: number
    created_at: string
    updated_at: string
    order: {
        status_text: string
        status: number
    }
}

export const hotelListModule = {
    //获取酒店列表
    getHotelList: (data: any) => sendGet<ListRes<GetHotelListRes>>('/backend/order-hotel/get-list', data)
}