import axios from 'axios'
import store from '@/redux/store'
import { loginExpiresChenge } from '@/redux/actions'
import { message } from 'antd';
import { logInModule } from './modules/logIn'
import { ticketListModule } from './modules/ticketList'
import { userListModule } from './modules/userList'
import { hotelListModule } from './modules/hotelList'
import type { ApiResponse } from './type'

export const sendGet = <R, D = any>(url: string, data?: D) => {
    return new Promise<ApiResponse<R>>((resolve, reject) => {
        axios.get(url, { params: data }).then(res => {
            resolve(res.data)
        }, err => {
            reject(err)
        })
    })
}

export const sendPost = <R, D = any>(url: string, data: D) => {
    return new Promise<ApiResponse<R>>(function (resolve, reject) {
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, err => {
            reject(err)
        })
    })
}

export const sendPut = <R, D = any>(url: string, data: D) => {
    return new Promise<ApiResponse<R>>(function (resolve, reject) {
        axios.put(url, data).then(res => {
            resolve(res.data)
        }, err => {
            reject(err)
        })
    })
}

// axios.defaults.timeout = 120000;
// axios.defaults.baseURL = window.configInfo.baseIP + ":" + window.configInfo.basePort
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = '/test'
} else {
    axios.defaults.baseURL = "https://app-api.shanghaizhuole.com"
}

axios.interceptors.request.use(
    (config: any) => {
        // config.withCredentials = true;  //允许携带cookie信息
        let TravelInternationalInformation = localStorage.getItem("TravelInternationalInformation")
        if (TravelInternationalInformation) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.token = JSON.parse(TravelInternationalInformation).token
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });

axios.interceptors.response.use(
    response => {        
        if (response.data.code) {
            if (response.data.code === 401) {
                store.dispatch(loginExpiresChenge(true))
                return Promise.reject()
            } else if (response.data.code !== 200) {
                message.error(response.data.message)
            }
        }
        return response
    }, error => {
        message.error(error.response.data)
        return Promise.reject(error)
    }
)


const requestList = {
    //登录模块
    ...logInModule,
    //用户列表模块
    ...userListModule,
    //机票列表模块
    ...ticketListModule,
    //酒店列表模块
    ...hotelListModule,
    //获取订单列表
    GetOrderList(data: any) {
        return sendGet('/orders', { params: data })
    },
    //获取订单详情
    getOrderDetails(id: any) {
        return sendGet(`/orders/${id}`)
    },
    //获取国家列表
    getCountryList(data: any) {
        return sendGet('/countries', { params: data })
    },
    //获取城市列表
    getCityList(data: any) {
        return sendGet('/cities', { params: data })
    },
    // 获取用户列表 管理
    adminGetUser(data: any) {
        return sendGet('/manage-users', { params: data })
    },
    //添加用户 管理
    adminAddUser(data: any) {
        return sendPost('/manage-users', data)
    },
    //添加用户 管理
    addUser(data: any) {
        return sendPost('/user/create', data)
    },
    //编辑用户 管理
    adminEditUser(data: any, id: any) {
        return sendPut(`/manage-users/${id}`, data)
    },
    // 获取用户列表 平台
    palletGetUser(data: any) {
        return sendGet('/users', { params: data })
    },
    // 上传
    getCosSign(name: any) {
        return sendGet(`/cos-credential/${name}`)
    },
    // 添加产品
    addProduct(data: any) {
        return sendPost('/services', data)
    },
    //修改产品
    editProduct(id, data) {
        return sendPut(`/services/${id}`, data)
    },
    // 获取产品列表
    getProductList(data) {
        return sendGet('/services', { params: data })
    },
    // 获取产品详情
    getProductDetails(id) {
        return sendGet(`/services/${id}`)
    },
}
export default requestList