import loadable from './Loadable'

const Logon = loadable(() => import('../views/LogIn/LogIn'))
const Home = loadable(() => import('../views/Home/Home'))
const OrderList = loadable(() => import('../views/OrderList/OrderList'))
const HotelList = loadable(() => import('../views/HotelList/HotelList'))
const CountryList = loadable(() => import('../views/CountryList/CountryList'))
const CityList = loadable(() => import('../views/CityList/CityList'))
const ProductList = loadable(() => import('../views/ProductList/ProductList'))
// 用户管理
const AdminUserLise = loadable(() => import('../views/UserManage/AdminUserLise/AdminUserLise'))
const PalletUserLise = loadable(() => import('../views/UserManage/PalletUserLise/PalletUserLise'))
const UserList = loadable(() => import('../views/UserList/UserList'))

const AddProduct = loadable(() => import('../views/AddProduct/AddProduct'))
const TicketList = loadable(() => import('../views/TicketList/TicketList'))

const routes = [
    {
        path: '/',
        element: <Logon />,
    },
    {
        path: '/Home',
        element: <Home />,
        children: [
            {
                path: "UserList",
                element: <UserList />
            },
            {
                path: "TicketList",
                element: <TicketList />
            },
            {
                path: "OrderList",
                element: <OrderList />
            },
            {
                path: "HotelList",
                element: <HotelList />
            },
            {
                path: "CountryList",
                element: <CountryList />
            },
            {
                path: "CityList",
                element: <CityList />
            },
            // 用户管理
            {
                path: "AdminUserLise",
                element: <AdminUserLise />
            },
            {
                path: "PalletUserLise",
                element: <PalletUserLise />
            },
            {
                path: "AddProduct",
                element: <AddProduct />
            },
            {
                path: "ProductList",
                element: <ProductList />
            },
        ]
    },
]

export default routes