import Loadable from 'react-loadable'
import RouteLoading from '@/components/RouteLoading/RouteLoading'

const loadable = (comp: any) => {
    return Loadable({
        loader: comp,
        loading:()=> <RouteLoading/>
    })
}

export default loadable